import { loadDemandContent, ATTR_DEMAND_CONTENT } from "../shared/ContentUtilities";

const KOHO_START_FALLBACK_HTML = `<a href="https://launch.kohoapp.net" class="btn btn-link text-decoration-none fw-bold">Connect to Koho Start &nbsp;<i class="fa fa-chevron-circle-right"></i></a>`;

export function setupApplicationTopBar(): void {

  // Koho App Button on the topbar
  const kohoAppsButton = $("#kohoAppsButton");
  if (kohoAppsButton) {
    kohoAppsButton.on("click", () => {
      kohoAppsButton.dropdown("show");
      const htmlContainer = $("#kohoAppsTopBarContentContainer");
      loadDemandContent(htmlContainer, (ok:boolean) => {
        if (!ok) {
          console.error("Failed to load Koho apps content.");
          htmlContainer.html(KOHO_START_FALLBACK_HTML);
        }
      });
    });
  }

  // Topbar Tools with dropdowns
  const toolDropDowns = $("#topBar button[koho-topbar-button-dropdown]");
  if (toolDropDowns) {
    toolDropDowns.on("click", (e) => {
      const button = $(e.target).closest("button");
      const container = button.parent().find(`div[${ATTR_DEMAND_CONTENT}]`).first();
      if (container && container.length > 0) {
        button.dropdown("show");
        loadDemandContent(container);
      }
    });
  }

    // Koho App Button on the topbar
    const userPassButton = $("#userPassButton");
    if (userPassButton) {
      userPassButton.on("click", () => {
        userPassButton.dropdown("show");
        const htmlContainer = $("#userPassTopBarContentContainer");
        loadDemandContent(htmlContainer, (ok:boolean) => {
          if (!ok) {
            console.error("Failed to load user pass content.");
            htmlContainer.html(KOHO_START_FALLBACK_HTML);
          }
        });
      });
    }
}