import bootstrap from "bootstrap";

export const EVENT_SIDE_BLADE_READY = "ready.koho.sideblade";

const SIDEBLADE_MODAL_ID = "#k2SideBladeContainer";
const SIDEBLADE_ACTION_ATTR = "sideblade-action";

export function setupApplicationSideBlade(container: JQuery = null): void {
  if (container === null) {
    container = $("*");
  }

  const sideBladeModal = $(SIDEBLADE_MODAL_ID);
  container.find(`[${SIDEBLADE_ACTION_ATTR}]`).on("click", e => {
    const action = $(e.target).closest(`[${SIDEBLADE_ACTION_ATTR}]`);
    const contentUri = action.attr(SIDEBLADE_ACTION_ATTR);
    if (contentUri === undefined) {
      console.error(`SideBlade URI not available. No closest ${SIDEBLADE_ACTION_ATTR} attribute value found near:`);
      console.error(e.target);
      return;
    }
    try {
      $.get(contentUri, (content: any, x: JQuery.jqXHR) => {
        sideBladeModal.html(content);
        SideBlade.getInstance().show();
        sideBladeModal.trigger(EVENT_SIDE_BLADE_READY, action.attr("id"));
      }).fail((x: any) => {
        console.error(x);
      });
    } catch (e) {
      console.error(e);
    } 
  });
}

export class SideBlade {

  private static offCanvasInstance: bootstrap.Offcanvas;

  static getInstance(): bootstrap.Offcanvas {
    if (!this.offCanvasInstance) {
      const sideBladeModal = $(SIDEBLADE_MODAL_ID);
      var offCanvas = new bootstrap.Offcanvas(sideBladeModal[0]);
      SideBlade.offCanvasInstance = offCanvas;
    }
    return SideBlade.offCanvasInstance;
  }

  //
  // Use this method to register a callback when a side blade is ready,
  // with its content loaded, but before the sideblade is shown on the UX.
  //
  // NOTE: This is intended to run custom JavaScript for the side blade 
  //       content. You do not need to register this callback unless you 
  //       intend to run some custom JavaScript on its content.
  //
  static onContentReady(callback:(modal:HTMLDivElement, sourceId: string | undefined) => void):void {
    const sideBladeModal = $(SIDEBLADE_MODAL_ID);
    sideBladeModal.on(EVENT_SIDE_BLADE_READY, (e: JQuery.EventBase, sourceId) => {
      callback(e.target as HTMLDivElement, sourceId);
    });
  }
}