import { kohoStart } from "./PageStart";
import { SideBlade } from "./chrome/SideBlade";

export {
  SideBlade
}

//
// jQuery PAGE STARTUP 
//
// This is the JavaScript / TypeScript entry point for koho pages. 
//
// When one of our pages (referencing "~/js/pages.js") is loaded, this jQuery function is 
// called when the document is ready.
//
// "Global" code that belongs in all pages starts here.
//
($ => {
  kohoStart();
})(jQuery);