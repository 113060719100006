import { Tooltip } from "bootstrap";

const CHEVRON_RIGHT = "<i class='fa fa-angle-right'></i>";
const CHEVRON_LEFT = "<i class='fa fa-angle-left'></i>";
export function setupApplicationSidebar(): void {
  const allRootLinks = $("nav.sidebar a.sidebar-root-link, nav.sidebar a.sidebar-child-link");
  const tooltips:Tooltip[] = [];
  allRootLinks.each((_, e) => {
    tooltips.push(new Tooltip(e));
  });
  for (let index = 0; index < tooltips.length; index++) {
    tooltips[index].disable();
  }

  const sidebar = $("nav.sidebar");
  const toggle = $(".sidebar-toggle");
  toggle.on("click", (e) => {
    let collapsed: boolean = false;
    if (sidebar.hasClass("collapsed")) {
      sidebar.removeClass("collapsed");
      toggle.html(CHEVRON_LEFT);
      allRootLinks.tooltip("disable");

      var shifted = $(".sidebar-shift-collapsed")
      shifted.addClass("sidebar-shift");
      shifted.removeClass("sidebar-shift-collapsed");
      collapsed = false;
    } else {
      sidebar.addClass("collapsed");
      toggle.html(CHEVRON_RIGHT);
      allRootLinks.tooltip("enable");

      var shifted = $(".sidebar-shift")
      shifted.addClass("sidebar-shift-collapsed");
      shifted.removeClass("sidebar-shift");
      collapsed = true;
    }
    
    saveSidebarState(toggle.data("save-state-uri"), { collapsed });
  });
}

interface ISidebarState {
  collapsed: boolean
}

function saveSidebarState(saveStateUri: string, state: ISidebarState)
{
  if (saveStateUri){
    console.debug(`Save sidebar state to '${saveStateUri}'`)
    const request = $.ajax({
      type: "PUT",
      url: saveStateUri,
      data: JSON.stringify(state),
      contentType: "application/json",
    });
    request.done((x:any) =>{
    });
    request.fail((x:any) =>{
      console.error(x);
    });
  }
}
