import "bootstrap";
import { Popover, Toast } from "bootstrap";
import { loadDynamicContent, setupKohoLocalTime } from "./shared/ContentUtilities";
import { setupApplicationTopBar } from "./chrome/TopBar";
import { setupApplicationSidebar } from "./chrome/Sidebar";
import { setupApplicationSideBlade } from "./chrome/SideBlade";

//
//
// PageReady.ts
//
// This TypeScript file is intended to encapsulate all the startup code that our
// pages need.
//
// The intention is that code in this file will execute for every page in the application
// in order to setup global styles, handlers, event listeners, etc.
//

//
// kohoStart() - called when a page is first loaded to initialize a page.
//

export function kohoStart() {
  // Bootstrap customizations
  setupBootstrapToasts();
  setupBootstrapPopovers();

  // Applications
  setupApplicationTopBar();
  setupApplicationSidebar();
  setupApplicationSideBlade();

  // Localization
  setupKohoLocalTime();

  // Dynamic content
  loadDynamicContent((element:JQuery) => {
    setupApplicationSideBlade(element); 
  });
}

// setup Bootstrap's popovers
function setupBootstrapPopovers(): void {
  $('[data-bs-toggle="popover"]').each((_, e) => {
    new Popover(e);
  });
}

// setup Bootstrap's toast notifications
function setupBootstrapToasts(): void {
  $(".toast").each((_, e) => {
    new Toast(e).show();
  });
}
