
//
// demand content is loaded on demand, typically in response to a user gesture on the UX.
//  - this is intended to load content (particularly "heavy" content) as the user needs it
//    rather than incurring the weight of the content every time the page is loaded.
//
export const ATTR_DEMAND_CONTENT = "koho-demand-content";
export function loadDemandContent(container: JQuery, callback: (ok: boolean) => void = null) {
  const contentUri = container.attr(ATTR_DEMAND_CONTENT);
  if (contentUri){
    if (contentUri.startsWith("https://") || contentUri.startsWith("http://")){
      // assume it is a request for content from a different origin, use CORS.
      loadContentFromUriWithCors(container, contentUri, callback);
    }
    else{
      loadContentFromUri(container, contentUri, callback);
    }
  }
}

//
// dynamic content is loaded automatically when the page is loaded, it doesn't require a user gesture.
//  - this is intended to load content "in the background" on page load so static elements of the
//    page are visible while other dynamic elements are loaded 
//
export const ATTR_DYNAMIC_CONTENT = "koho-dynamic-content";
export function loadDynamicContent(callback: (dynamicElement:JQuery) => void = null) {
  const elements = $(`[${ATTR_DYNAMIC_CONTENT}]`);
  elements.each((i: number, e: HTMLElement) => {
    const next = $(e);
    const contentUri = next.attr(ATTR_DYNAMIC_CONTENT);
    if (contentUri){
      if (contentUri.startsWith("https://") || contentUri.startsWith("http://")){
        // assume it is a request for content from a different origin, use CORS.
        loadContentFromUriWithCors(next, contentUri, (ok => {
          if (ok && callback !== null) {
            callback(next);
          }
        }));
      }
      else{
        loadContentFromUri(next, contentUri, (ok => {
          if (ok && callback !== null) {
            callback(next);
          }
        }));
      }
    } 
  });
}

//
// Set the specified container's content from HTML content loaded from the specified URI. 
//
export function loadContentFromUri(container: JQuery, contentUri: string, callback: (ok: boolean) => void = null): void {
    if (contentUri) {
      try {
        console.debug(`Loading content from ${contentUri}.`);
        $.get(contentUri, (content: any, x: JQuery.jqXHR) => {
          container.html(content);
          if (callback != null) {
            callback(true);
          }
        }).fail((x: any) => {
          container.html(`<span class="text-danger">ERROR ${x.status}: Failed to load content from ${contentUri}.</span>`);
          console.error(x);
          if (callback != null) {
            callback(false);
          }
        });
      } catch (e) {
        console.error(e);
        if (callback != null) {
          callback(false);
        }
      }
    }
  }
  
export function loadContentFromUriWithCors(container: JQuery, contentUri: string, callback: (ok: boolean) => void = null): void {
  if (contentUri) {
    try {
      console.debug(`Loading content with CORS from ${contentUri}.`);
      $.ajax({
        method: "GET",
        url: contentUri,
        crossDomain: true,
        xhrFields: {
          withCredentials : true
        }
      })
      .done((content: any) => {
          container.html(content);
          if (callback != null) {
            callback(true);
          }
        })
      .fail((x: any) => {
          container.html(`<span class="text-danger">ERROR ${x.status}: Failed to load content from ${contentUri}.</span>`);
          console.error(x);
          if (callback != null) {
            callback(false);
          }
        });
      } catch (e) {
        console.error(e);
        if (callback != null) {
          callback(false);
        }
      }
    }
  }

  export function getJsonData<TData>(uri: string, callback: (data: TData) => void = null): void {
    if (uri) {
      try {
        console.debug(`Loading JSON data from ${uri}.`);
        $.getJSON(uri, (data: any, x: JQuery.jqXHR) => {
          if (callback != null) {
            callback(data as TData);
          }
        }).fail((x: any) => {
          console.error(x);
          if (callback != null) {
            callback(null);
          }
        });
      } catch (e) {
        console.error(e);
        if (callback != null) {
          callback(null);
        }
      }
    }
  }
  
  // setup UTC to local time conversion
  const ATTR_UTC_TO_LOCALTIME = "koho-utc-to-localtime";
  
  export function setupKohoLocalTime(): void {
    $(`[${ATTR_UTC_TO_LOCALTIME}]`).each((i: number, e: HTMLElement) => {
      const next = $(e);
      const utc = next.attr(ATTR_UTC_TO_LOCALTIME);
      if (utc) {
        try {
          const date = new Date(utc);
          next.text(date.toLocaleTimeString());
        } catch (e) {
          console.error(e);
        }
      }
    });
  }